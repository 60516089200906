<template>
	<PaddingContainer
		component="section"
		class="featured-items-grid-wrapper"
		:data-variant="variant"
		:padding="padding"
		:use-fallback-container="shouldUseDefaultContainer"
	>
		<div class="heading-container">
			<h2 class="heading-font grid-title">
				{{ title }}
			</h2>

			<span
				v-if="description"
				class="default-font grid-description allow-line-break"
			>
				{{ fixEscapedLineBreaks(description) }}
			</span>

			<span v-if="showSeparator" class="separator"></span>
		</div>
		<div class="featured-items-grid">
			<FeaturedGridItem
				v-for="item in items"
				:key="item.text"
				:text="item.text"
				:description="item.description"
				:image-url="item.imageUrl"
				:url="item.url"
				:text-color="itemTextColor"
				:variant="variant"
				:overlay="overlay"
			/>
		</div>
	</PaddingContainer>
</template>

<script setup lang="ts">
import type { FeaturedItemsGridSection } from '@SHARED/core/entities/sections/FeaturedItemsGridSection';
import type { WebsiteStyles } from '@SHARED/core/entities/WebsiteConfig';

import { getCSSColorVar } from '@SHARED/utils/style';
import { fixEscapedLineBreaks } from '@SHARED/utils';
import { COLOR_SERVICE } from '@SHARED/utils/vueProvidersSymbols';

import PaddingContainer from '@SHARED/components/molecules/PaddingContainer.vue';
import FeaturedGridItem from '@/components/atoms/FeaturedGridItem.vue';

defineOptions({ name: 'FeaturedItemsGridSection' });

const styles = useState<WebsiteStyles>('styles');

const colorService = inject(COLOR_SERVICE)!;

const props = withDefaults(defineProps<FeaturedItemsGridSection['config']>(), {
	title: null,
	showSeparator: false,
	overlayOpacity: 0,
	overlayColor: 'black',
	itemsPerRow: 3,
	variant: 'default',
	itemGap: '0px',
	shouldUseDefaultContainer: false
});

const itemTextColor = computed<string>(() =>
	getCSSColorVar(props?.textColor || styles.value.appearance.text)
);

const overlay = computed<string>(() => {
	if (!props.overlayOpacity || !props.overlayColor) {
		return 'rgba(0,0,0,0)';
	}

	const opacity = 1 - props.overlayOpacity;

	return colorService.transparentizeColor(
		styles.value.colorPalette[props.overlayColor],
		opacity
	);
});

const itemsPerRow = computed<number>(() =>
	props.itemsPerRow > props.items.length
		? props.items.length
		: props.itemsPerRow
);
</script>

<style lang="scss" scoped>
.featured-items-grid-wrapper {
	width: 100%;
	background-color: var(--white);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.heading-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 0.75rem;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;

		.grid-title {
			font-size: 2rem;
			text-align: center;

			@include screen-up(lg) {
				font-size: 2.5rem;
			}
		}

		.grid-description {
			font-size: 1.25rem;
			text-align: center;
		}
	}

	.featured-items-grid {
		width: 100%;
	}

	&[data-variant='default'] {
		.featured-items-grid {
			display: grid;
			grid-template-columns: repeat(1, 1fr);
			gap: v-bind(itemGap);

			@include screen-up(lg) {
				grid-template-columns: repeat(v-bind(itemsPerRow), 1fr);
			}
		}
	}

	&[data-variant='vertical'] {
		.featured-items-grid {
			display: flex;
			flex-direction: column;
			gap: v-bind(itemGap);
		}
	}
}
</style>
